<template>
  <v-card tile>
    <v-toolbar elevation="1" dense>
      <v-toolbar-title>
        <span class="text-caption" v-text="'INGRESO'" />
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn icon @click.prevent="show_info = !show_info">
          <v-icon v-text="`mdi-chevron-${show_info ? 'up' : 'down'}`" />
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text v-if="rha && show_info">
      <v-row dense>
        <v-col cols="12" sm="12" md="3">
          <ViewData label="Folio" :value="rha.folio" />
        </v-col>
        <v-col v-if="rha && rha.rha_surgical_documents.length > 0" cols="12">
          <span class="text-caption font-weight-bold"> N. Quirúrgicas </span>
          <br />
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="20px" v-text="'#'" />
                  <th v-text="'Tipo'" />
                  <th v-text="'Descripción'" />
                  <th v-text="'IDNQ'" />
                  <th v-text="'Fecha'" />
                  <th width="50px" />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    rha_surgical_document, i
                  ) in rha.rha_surgical_documents"
                  :key="i"
                >
                  <td class="font-weight-bold" v-text="i + 1" />
                  <td v-text="rha_surgical_document.document" />
                  <td v-text="rha_surgical_document.description" />
                  <td v-text="rha_surgical_document.rha_surgical_id" />
                  <td v-text="rha_surgical_document.created_at" />
                  <td>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          x-small
                          icon
                          color="warning"
                          :href="
                            url_documents +
                            '/rha_surgicals/' +
                            rha_surgical_document.url
                          "
                          target="_blank"
                        >
                          <v-icon> mdi-download </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Descargar'" />
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col v-if="rha && rha.rha_evolution_documents.length > 0" cols="12">
          <span class="text-caption font-weight-bold"> N. Evolución </span>
          <br />
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="20px" v-text="'#'" />
                  <th v-text="'Tipo'" />
                  <th v-text="'Descripción'" />
                  <th v-text="'IDNE'" />
                  <th v-text="'Fecha'" />
                  <th width="50px" />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    rha_evolution_document, i
                  ) in rha.rha_evolution_documents"
                  :key="i"
                >
                  <td class="font-weight-bold" v-text="i + 1" />
                  <td v-text="rha_evolution_document.document" />
                  <td v-text="rha_evolution_document.description" />
                  <td v-text="rha_evolution_document.rha_evolution_id" />
                  <td v-text="rha_evolution_document.created_at" />
                  <td>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          x-small
                          icon
                          color="warning"
                          :href="
                            url_documents +
                            '/rha_evolutions/' +
                            rha_evolution_document.url
                          "
                          target="_blank"
                        >
                          <v-icon> mdi-download </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Descargar'" />
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { URL_DOCUMENTS } from "../control";
import ViewData from "./ViewData.vue";

export default {
  components: {
    ViewData,
  },
    props: ["rha"],
  data() {
    return {
      url_documents: URL_DOCUMENTS,
      show_info: true,
    };
  },
  methods: {},
  mounted() {},
};
</script>