var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s('INGRESO')}})]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();_vm.show_info = !_vm.show_info}}},[_c('v-icon',{domProps:{"textContent":_vm._s(("mdi-chevron-" + (_vm.show_info ? 'up' : 'down')))}})],1)],1)],1),(_vm.rha && _vm.show_info)?_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Folio","value":_vm.rha.folio}})],1),(_vm.rha && _vm.rha.rha_surgical_documents.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption font-weight-bold"},[_vm._v(" N. Quirúrgicas ")]),_c('br'),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20px"},domProps:{"textContent":_vm._s('#')}}),_c('th',{domProps:{"textContent":_vm._s('Tipo')}}),_c('th',{domProps:{"textContent":_vm._s('Descripción')}}),_c('th',{domProps:{"textContent":_vm._s('IDNQ')}}),_c('th',{domProps:{"textContent":_vm._s('Fecha')}}),_c('th',{attrs:{"width":"50px"}})])]),_c('tbody',_vm._l((_vm.rha.rha_surgical_documents),function(rha_surgical_document,i){return _c('tr',{key:i},[_c('td',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(i + 1)}}),_c('td',{domProps:{"textContent":_vm._s(rha_surgical_document.document)}}),_c('td',{domProps:{"textContent":_vm._s(rha_surgical_document.description)}}),_c('td',{domProps:{"textContent":_vm._s(rha_surgical_document.rha_surgical_id)}}),_c('td',{domProps:{"textContent":_vm._s(rha_surgical_document.created_at)}}),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","icon":"","color":"warning","href":_vm.url_documents +
                          '/rha_surgicals/' +
                          rha_surgical_document.url,"target":"_blank"}},on),[_c('v-icon',[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Descargar')}})])],1)])}),0)]},proxy:true}],null,false,2364284139)})],1):_vm._e(),(_vm.rha && _vm.rha.rha_evolution_documents.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption font-weight-bold"},[_vm._v(" N. Evolución ")]),_c('br'),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20px"},domProps:{"textContent":_vm._s('#')}}),_c('th',{domProps:{"textContent":_vm._s('Tipo')}}),_c('th',{domProps:{"textContent":_vm._s('Descripción')}}),_c('th',{domProps:{"textContent":_vm._s('IDNE')}}),_c('th',{domProps:{"textContent":_vm._s('Fecha')}}),_c('th',{attrs:{"width":"50px"}})])]),_c('tbody',_vm._l((_vm.rha.rha_evolution_documents),function(rha_evolution_document,i){return _c('tr',{key:i},[_c('td',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(i + 1)}}),_c('td',{domProps:{"textContent":_vm._s(rha_evolution_document.document)}}),_c('td',{domProps:{"textContent":_vm._s(rha_evolution_document.description)}}),_c('td',{domProps:{"textContent":_vm._s(rha_evolution_document.rha_evolution_id)}}),_c('td',{domProps:{"textContent":_vm._s(rha_evolution_document.created_at)}}),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","icon":"","color":"warning","href":_vm.url_documents +
                          '/rha_evolutions/' +
                          rha_evolution_document.url,"target":"_blank"}},on),[_c('v-icon',[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Descargar')}})])],1)])}),0)]},proxy:true}],null,false,3777842738)})],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }