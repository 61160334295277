<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'servicios',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              $route.meta.title +
              (tbl_data.length > 0 ? ' (' + tbl_data.length + ')' : '')
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-select
            label="Estado"
            v-model="tbl_filter"
            :items="tbl_filters"
            item-value="id"
            :item-text="(v) => v.value"
            dense
            :disabled="loading"
            @change="getTableData"
          />
        </v-col>
        <v-col v-if="tbl_data.length > 0" cols="12" xs="12" md="8">
          <v-text-field
            v-model="tbl_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_headers"
            :search="tbl_search"
            :items="tbl_data"
            :loading="loading"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.key="{ item, index }">
              <b v-text="index + 1" />
            </template>
            <template v-slot:item.discharged="{ item }">
              <span v-text="item.discharged ? item.discharged : 'PEND.'" />
            </template>
            <template v-slot:item.doctor.url_validation="{ item }">
              <span v-text="item.doctor.url_validation ? 'SI' : 'PEND.'" />
            </template>
            <template v-slot:item.action="{ item, index }">
              <div class="text-right">
                <v-tooltip v-if="login.permissions.doctors.read" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="secondary"
                      x-small
                      fab
                      class="ml-1"
                      :to="{
                        name: 'doctors.view',
                        params: { id: item.doctor_id },
                      }"
                      target="_blank"
                    >
                      <v-icon> mdi-doctor </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Médico | Ver detalle'" />
                </v-tooltip>
                <v-tooltip v-if="item.rha" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="success"
                      x-small
                      fab
                      class="ml-1"
                      @click.prevent="rhaDlg(item.folio_full, item.rha)"
                    >
                      <v-icon> mdi-file-sign </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Detalle de ingreso'" />
                </v-tooltip>
                <v-tooltip v-if="!item.doctor.url_validation" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="warning"
                      x-small
                      fab
                      class="ml-1"
                      @click="
                        login.permissions.rss_doctors_url_validation.update
                          ? DoctorUrlValidDlg(item, index)
                          : false
                      "
                    >
                      <v-icon> mdi-alert </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Médico | Archivo valid. pendiente'" />
                </v-tooltip>
                <v-tooltip
                  v-if="
                    login.permissions.rss_doctors_url_validation.read &&
                    item.doctor.url_validation
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="secondary"
                      x-small
                      fab
                      class="ml-1"
                      :href="
                        url_documents + '/doctors/' + item.doctor.url_validation
                      "
                      target="_blank"
                    >
                      <v-icon> mdi-file </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Médico | Ver archivo valid.'" />
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="primary"
                      :to="{
                        name: `servicios.detalle`,
                        params: { id: item.rs_id },
                      }"
                      x-small
                      dark
                      fab
                      class="ml-1"
                    >
                      <v-icon> mdi-file-eye </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Detalle de servicio'" />
                </v-tooltip>
                <v-tooltip
                  v-if="login.permissions.rss_doctors_validation.update"
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      x-small
                      color=""
                      @click="validDlg(item, index)"
                      class="ml-1"
                    >
                      <v-icon> mdi-decagram </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Sin validar'" />
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="valid_dlg" persistent max-width="500">
      <v-card tile :disabled="valid_dlg_ldg" :loading="valid_dlg_ldg">
        <v-toolbar dark>
          <v-toolbar-title> Validación </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="valid_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="valid">
          <v-row dense>
            <v-col cols="12" class="mt-3 text-center">
              <ViewData
                label="Médico"
                :value="valid.doctor_id + ' | ' + valid.doctor.full_name"
              />
            </v-col>
            <v-col
              v-if="rs_services && rs_services.length > 0"
              cols="12"
              class="text-center"
            >
              <div>
                <b>Servicios que generaran carta</b>
              </div>
              <div v-for="(rs_service, i) in rs_services" :key="i">
                <span>{{ rs_service.folio_full }}</span>
              </div>
            </v-col>
            <v-col cols="12">
              <v-btn
                small
                block
                color="success"
                @click.prevent="validDlgHandle(true)"
              >
                <v-icon left> mdi-check-decagram </v-icon>
                Aceptar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="doctor_url_valid_dlg" persistent max-width="500">
      <v-card
        tile
        :disabled="doctor_url_valid_dlg_ldg"
        :loading="doctor_url_valid_dlg_ldg"
      >
        <v-toolbar dark>
          <v-toolbar-title> Archivo validación pendiente </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="doctor_url_valid_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="doctor_url_valid">
          <v-form
            v-on:submit.prevent
            ref="doctor_url_valid_form"
            lazy-validation
          >
            <v-row dense>
              <v-col cols="12" class="mt-3">
                <ViewData
                  label="Médico"
                  :value="
                    doctor_url_valid.doctor_id +
                    ' | ' +
                    doctor_url_valid.doctor.full_name
                  "
                />
              </v-col>
              <v-col cols="12">
                <v-file-input
                  label="Archivo digital*"
                  v-model="doctor_url_valid.file"
                  accept="image/,.pdf"
                  :rules="rules.required"
                  show-size
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  small
                  block
                  color="success"
                  @click.prevent="DoctorUrlValidHandle(true)"
                >
                  Enviar
                  <v-icon right> mdi-send </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rha_dlg" persistent max-width="900">
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title> Servicio | {{ folio_full }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="rha_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="rha">
          <v-col cols="12">
            <RhaWithRsService :rha="rha" />
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgAlert,
  msgConfirm,
  rules,
  toFormData,
  URL_DOCUMENTS,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";
import RhaWithRsService from "../../components/RhaWithRsService.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
    RhaWithRsService,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      loading: false,
      tbl_data: [],
      tbl_search: "",
      tbl_headers: [
        { text: "#", filterable: false, value: "key", width: "50" },
        { text: "Folio", filterable: true, value: "folio_full" },
        { text: "Tipo", filterable: true, value: "type" },
        {
          text: "Terminado",
          filterable: true,
          value: "discharged",
        },
        { text: "IDM", filterable: true, value: "doctor_id" },
        { text: "Médico", filterable: true, value: "doctor.full_name" },
        {
          text: "Consultorio",
          filterable: true,
          value: "doctor.consulting_room.address",
        },
        {
          text: "Hospital",
          filterable: true,
          value: "doctor.consulting_room.provider.trade_name",
        },
        {
          text: "Asegurado",
          filterable: true,
          value: "insured_full_name",
        },
        {
          text: "",
          fixed: true,
          value: "action",
          sortable: false,
          width: "230",
        },
      ],
      tbl_filter: null,
      tbl_filters: [
        { id: 1, value: "PENDIENTES" },
        { id: 2, value: "PAGOS AL DÍA" },
        { id: 3, value: "HONORARIOS MÉDICOS PEND. CARTA SERVICIO" },
        { id: 4, value: "PENDIENTES DE VALIDAR SEGUNDA FIRMA" },
      ],
      rules: rules(),
      url_documents: URL_DOCUMENTS,
      index: null,
      valid: null,
      valid_dlg: false,
      valid_dlg_ldg: false,
      doctor_url_valid: null,
      doctor_url_valid_dlg: false,
      doctor_url_valid_dlg_ldg: false,
      folio_full: null,
      rha: null,
      rha_dlg: false,
      rs_services: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getTableData() {
      this.loading = true;
      this.tbl_data = [];

      Axios.get(
        URL_API + "/rss/services/doctors/validate?filter=" + this.tbl_filter,
        headersToken(this.login.token)
      ).then((res) => {
        this.tbl_data = res.data.data;
        this.loading = false;
      });
    },
    validDlg(item, index) {
      this.index = index;
      this.rs_services = item.rs_services;
      this.valid = item;
      this.valid_dlg = true;
      this.valid_dlg_ldg = false;
    },
    validDlgHandle(valid) {
      this.$swal
        .fire(msgConfirm("¿Confirma marcar como valido al médico?"))
        .then((res) => {
          if (res.isConfirmed) {
            this.valid_dlg_ldg = true;

            Axios.post(
              URL_API + "/doctors/valid",
              {
                id: this.valid.doctor_id,
                valid: valid,
                rs_services: this.valid.rs_services,
              },
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.tbl_data = this.tbl_data.filter(
                  (i) => i.doctor_id != this.valid.doctor_id
                );

                this.valid_dlg = false;
              } else {
                console.log(resp.data.message);
              }

              this.valid_dlg_ldg = false;
            });
          }
        });
    },
    DoctorUrlValidDlg(item, index) {
      this.index = index;
      this.doctor_url_valid = item;
      this.doctor_url_valid.file = null;
      this.doctor_url_valid_dlg = true;
      this.doctor_url_valid_dlg_ldg = false;
    },
    DoctorUrlValidHandle() {
      if (this.$refs.doctor_url_valid_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma enviar información?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.doctor_url_valid_dlg_ldg = true;

              Axios.post(
                URL_API + "/doctors/validation/document",
                toFormData({
                  doctor_id: this.doctor_url_valid.doctor_id,
                  document_validation_0: this.doctor_url_valid.file,
                }),
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.tbl_data[this.index].doctor.url_validation =
                    res.data.data.url_validation;
                  this.doctor_url_valid_dlg = false;
                } else {
                  console.log(resp.data.message);
                }

                this.doctor_url_valid_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    rhaDlg(folio_full, rha) {
      this.folio_full = folio_full;
      this.rha = rha;
      this.rha_dlg = true;
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });
  },
};
</script>